import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import Ticker from '../../Components/Controllers/Ticker';
import { firstPostSelector, isLoadingSelector, stickyContentSelector, tickPosts } from '../../Redux/postsSlice';
import { matchUrl } from '../Routes';
import Article from './Article';
import { withAppContext } from '../../ContextProviders/AppContextProvider';
import MetaData from '../../Components/MetaData';

// Not used
const Detail = (appType) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const routeParams = useParams();
  const isLoading = useSelector(isLoadingSelector);
  const post = useSelector(firstPostSelector);
  const stickyContent = useSelector(stickyContentSelector);

  const onTick = () => {
    const routeMatch = matchUrl(location.pathname, appType);
    dispatch(tickPosts({ routeMatch, routeParams }));
  };

  return (
    <>
      {appType === 'app' && <MetaData metaData={stickyContent} />}

      <Ticker onTick={onTick} onlyOnce={true} skipFirst={false} tickerSpeedSeconds={30} lastTimestamp={0} timeoutAfterSeconds={0} fireOnChange={[]} />

      {isLoading && <></>}

      {!isLoading && post === null && <div>404 Post not found</div>}

      {!isLoading && post !== null && (
        <div>
          <Article article={post} />
          {/******************** Share links ****************************/}
          <div>
            {/* <SharingButtonsList
              articleObject={post}
              shareToItems={[
                /// Enabled Sharing buttons
                ShareTo.FACEBOOK,
                ShareTo.TWITTER,
                ShareTo.WHATSAPP,
                ShareTo.COPYTOCLIPBOARD,
              ]}
            /> */}
          </div>
        </div>
      )}
    </>
  );
};

export default withAppContext(Detail);
