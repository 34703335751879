import React, { memo } from 'react';
import SpriteMap from './SpriteMap';

const EmbedButton = ({ label, url, useEmbeded, onClick, backgroundColor }) => {
  return (
    <div>
      {useEmbeded && (
        <button onClick={onClick} className={'mnt-btn mnt-highlight mnt-btn-small'} style={backgroundColor ? { backgroundColor: backgroundColor } : {}}>
          {/*@TODO  - dat podminku na play button a zobrazit ak sa jedna o odkaz na video*/}
          <SpriteMap sprite={'external-play'} />
          <span>{label}</span>
          <SpriteMap sprite={'external-link'} />
        </button>
      )}
      {!useEmbeded && (
        <a href={url} className={'mnt-btn mnt-highlight mnt-btn-small'} target="_blank" rel="noreferrer" style={backgroundColor ? { backgroundColor: backgroundColor } : {}}>
          {/*@TODO  - dat podminku na play button a zobrazit ak sa jedna o odkaz na video*/}
          <SpriteMap sprite={'external-play'} />
          <span>{label}</span>
          <SpriteMap sprite={'external-link'} />
        </a>
      )}
    </div>
  );
};

export default memo(EmbedButton);
