import React, { useEffect, useMemo, useRef, useState } from 'react';
import TagLink from '../TagLink';
import AdminEditLink from '../AdminEditLink';
import SpriteMap from '../SpriteMap';
import { withGTMMTrackerContext } from '../../ContextProviders/GTMTrackerContextProvider';
import styled, { css } from 'styled-components';
import parse from 'html-react-parser';
import { withMessages } from '../../ContextProviders/LanguageContextProvider';

const StyledArticle = styled.div`
  ${(props) =>
    props.fontSetting &&
    props.fontSetting.length > 0 &&
    css`
      & {
        font-size: ${props.fontSetting[0][0]}px !important;
        line-height: ${props.fontSetting[0][1]}px !important;
      }
      & p {
        font-size: ${props.fontSetting[0][0]}px !important;
        line-height: ${props.fontSetting[0][1]}px !important;
      }
      & ul li {
        font-size: ${props.fontSetting[0][0]}px !important;
        line-height: ${props.fontSetting[0][1]}px !important;
      }
    `}
`;

const FeedStickyHeaderBlock = ({ stickyContent, trackMoreTopics, dynamicFontSize, messages }) => {
  const showOnlyExcerpt = stickyContent.type === 'live';
  const [classes, setClasses] = useState(stickyContent.excerpt ? 'mnt-articleWithExcerpt' : 'mnt-articleOnlyTags');
  const [scrolledDistance, setScrolledDistance] = useState(0);
  const [scrollWidth, setScrollWidth] = useState(0);
  const [boundsWidth, setBoundsWidth] = useState(0);
  const [resizeObserver, setResizeObserver] = useState();
  const tagsNavigation = useRef();

  const parsedExcerpt = useMemo(() => {
    return parse(stickyContent.excerpt, {});
  }, [stickyContent.excerpt]);

  const scrollTagsRight = () => {
    tagsNavigation.current.scrollTo({
      left: tagsNavigation.current.scrollWidth - tagsNavigation.current.getBoundingClientRect().width,
      behavior: 'smooth',
    });
    trackMoreTopics();
  };

  const scrollTagsLeft = () => {
    tagsNavigation.current.scrollTo({
      left: 0,
      behavior: 'smooth',
    });
  };

  const getScrolledDistance = () => {
    if (tagsNavigation.current) {
      setScrolledDistance(tagsNavigation.current.scrollLeft);
      setScrollWidth(tagsNavigation.current.scrollWidth);
      setBoundsWidth(Math.max(tagsNavigation.current.getBoundingClientRect().width, tagsNavigation.current.offsetWidth));
    }
  };

  const handleResize = () => {
    if (tagsNavigation.current) {
      setScrolledDistance(tagsNavigation.current.scrollLeft);
      setScrollWidth(tagsNavigation.current.scrollWidth);
      setBoundsWidth(Math.max(tagsNavigation.current.getBoundingClientRect().width, tagsNavigation.current.offsetWidth));
    }
  };

  useEffect(() => {
    tagsNavigation.current && resizeObserver && resizeObserver.observe(tagsNavigation.current);
  }, [tagsNavigation.current]);

  useEffect(() => {
    if (tagsNavigation.current) {
      setScrollWidth(tagsNavigation.current.scrollWidth);
      setBoundsWidth(Math.max(tagsNavigation.current.getBoundingClientRect().width, tagsNavigation.current.offsetWidth));
      tagsNavigation.current.addEventListener('scroll', getScrolledDistance);
    }
  }, [tagsNavigation.current]);

  useEffect(() => {
    if (!window.ResizeObserver) return false;
    tagsNavigation.current &&
      setResizeObserver(
        new ResizeObserver((entries) => {
          for (let entry of entries) {
            setScrollWidth(entry.target.scrollWidth);
            setBoundsWidth(Math.max(entry.target.getBoundingClientRect().width, entry.target.offsetWidth));
          }
        })
      );
    tagsNavigation.current && window.addEventListener('resize', handleResize);

    return () => {
      tagsNavigation.current && window.removeEventListener('resize', handleResize);
    };
  }, [tagsNavigation.current]);

  useEffect(() => {
    showOnlyExcerpt && !stickyContent.excerpt && setClasses('mnt-hidden');
  }, [stickyContent]);

  const articlesN = APP_LANG === 'sk' ? 'Články Denníka N o téme' : 'Články Deníku N o tématu';

  const SKcontent = (
    <>
      <article className={classes}>
        <AdminEditLink url={stickyContent.type === 'live' ? ENV_EDIT_LIVE_URL : ENV_EDIT_LATEST_URL} id={stickyContent.id} />
        {stickyContent.excerpt && (
          <StyledArticle className={'mnt-header-excerpt'} fontSetting={dynamicFontSize}>
            {parsedExcerpt}
          </StyledArticle>
        )}
        {stickyContent.web_url && stickyContent.name && (
          <p className={'mnt-articles-about'} fontSetting={dynamicFontSize}>
            <a href={stickyContent.web_url}>
              {articlesN} {stickyContent.name}
            </a>
          </p>
        )}
      </article>
      {!showOnlyExcerpt && (!!stickyContent.trending_tags.length || !!stickyContent.tags.length) && (
        <div className={'mnt-tags-wrapper'}>
          <div className={'mnt-tags'} ref={tagsNavigation} key={null} role="region" aria-label={messages.areaPopularPostsList}>
            {stickyContent.tags.map((tag) => (
              <TagLink key={tag.id} id={tag.id} slug={tag.slug} settings={stickyContent.trending_tags ? ['highlight'] : []}>
                {tag.name}
              </TagLink>
            ))}
            {stickyContent.trending_tags.length > 0 &&
              stickyContent.trending_tags.map((tag) => (
                <TagLink key={tag.id} id={tag.id} slug={tag.slug} settings={[]}>
                  {tag.name}
                </TagLink>
              ))}
          </div>
          {tagsNavigation.current && scrolledDistance > 0 && (
            <div className={'mnt-carret mnt-carret-left'}>
              <button onClick={scrollTagsLeft} aria-label={messages.areaTagsLeft}>
                <SpriteMap sprite={'carret-left'} />
              </button>
            </div>
          )}
          {tagsNavigation.current && scrolledDistance < scrollWidth - boundsWidth && (
            <div className={'mnt-carret mnt-carret-right'}>
              <button onClick={scrollTagsRight} aria-label={messages.areaTagsRight}>
                <SpriteMap sprite={'carret-right'} />
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );

  const CZcontent = (
    <article className={classes}>
      <AdminEditLink url={stickyContent.type === 'live' ? ENV_EDIT_LIVE_URL : ENV_EDIT_LATEST_URL} id={stickyContent.id} />
      {stickyContent.excerpt && (
        <StyledArticle className={'mnt-header-excerpt'} fontSetting={dynamicFontSize}>
          {parsedExcerpt}
        </StyledArticle>
      )}
      {stickyContent.web_url && stickyContent.name && (
        <p className={'mnt-articles-about'} fontSetting={dynamicFontSize}>
          <a href={stickyContent.web_url}>
            {articlesN} {stickyContent.name}
          </a>
        </p>
      )}

      {!showOnlyExcerpt && (!!stickyContent.trending_tags.length || !!stickyContent.tags.length) && (
        <div className={'mnt-tags-wrapper'}>
          <div className={'mnt-tags'} ref={tagsNavigation} key={null} role="region" aria-label={messages.areaPopularPostsList}>
            {stickyContent.tags.map((tag) => (
              <TagLink key={tag.id} id={tag.id} slug={tag.slug} settings={stickyContent.trending_tags ? ['highlight'] : []}>
                {tag.name}
              </TagLink>
            ))}
            {stickyContent.trending_tags.length > 0 &&
              stickyContent.trending_tags.map((tag) => (
                <TagLink key={tag.id} id={tag.id} slug={tag.slug} settings={[]}>
                  {tag.name}
                </TagLink>
              ))}
          </div>
          {tagsNavigation.current && scrolledDistance > 0 && (
            <div className={'mnt-carret mnt-carret-left'}>
              <button onClick={scrollTagsLeft} aria-label={messages.areaTagsLeft}>
                <SpriteMap sprite={'carret-left'} />
              </button>
            </div>
          )}
          {tagsNavigation.current && scrolledDistance < scrollWidth - boundsWidth && (
            <div className={'mnt-carret mnt-carret-right'}>
              <button onClick={scrollTagsRight} aria-label={messages.areaTagsRight}>
                <SpriteMap sprite={'carret-right'} />
              </button>
            </div>
          )}
        </div>
      )}
    </article>
  );

  return <>{APP_LANG === 'sk' ? SKcontent : CZcontent}</>;
};

export default withGTMMTrackerContext(withMessages(FeedStickyHeaderBlock));
