/// Merging utils

/**
 * creates new array as: original - differenceWith
 *
 * @param {*} original
 * @param {*} differenceWith
 * @param {*} fnGetUID
 */
export const difference = (original, differenceWith, fnGetUID) => {
  const newIds = Array.isArray(differenceWith) ? differenceWith.map(fnGetUID) : [];
  return original.filter((it) => newIds.indexOf(fnGetUID(it)) === -1);
};
