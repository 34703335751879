import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import TagLink from './TagLink';
import ArticleImage from './ArticleImage';
import SpriteMap from './SpriteMap';
import { withMessages } from '../ContextProviders/LanguageContextProvider';
import { Waypoint } from 'react-waypoint';
import EmbedButton from './EmbedButton';
import { withAppContext } from '../ContextProviders/AppContextProvider';
import { withGallery } from '../ContextProviders/GalleryContextProvider';
import { withGTMMTrackerContext } from '../ContextProviders/GTMTrackerContextProvider';
import ArticleEmbed from './ArticleEmbed';
import useMarkAsReadWaypoint from './Hooks/useMarkAsRead';
import useFeedConfiguration from './Hooks/useFeedConfiguration';
import FeedArticleToolbar from './FeedArticle/FeedArticleToolbar';
import useTryGallery from './Hooks/useTryGallery';
import useScrollableAncestor from './Hooks/useScrollableAncestor';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { withUserSettingsContext } from '../ContextProviders/UserSettingsContextProvider';

const StyledArticle = styled.div`
  ${(props) =>
    props.fontSetting &&
    props.fontSetting.length > 0 &&
    css`
      & p {
        font-size: ${props.fontSetting[0][0]}px !important;
        line-height: ${props.fontSetting[0][1]}px !important;
      }
      & .mnt-Important-sk p:first-child {
        font-size: ${props.fontSetting[1][0]}px !important;
        line-height: ${props.fontSetting[1][1]}px !important;
      }
      & ul li {
        font-size: ${props.fontSetting[0][0]}px !important;
        line-height: ${props.fontSetting[0][1]}px !important;
      }
    `}
`;

const FeedArticle = ({ article, showShareMenu, toggleShareMenuFn, messages, seen, openGallery, trackReadMore, appType, expanded, hideImage, dynamicFontSize, contentType, isDarkMode }) => {
  const thisDiv = useRef(null);
  const scrollableAncestor = useScrollableAncestor(appType);
  const [isLive] = useState(() => expanded || false);
  const [isExpanded, setIsExpanded] = useState(() => expanded || false);
  const [internalContentBackground, setInternalContentBackground] = useState(false);
  const [readMoreLabel, setReadMoreLabel] = useState('');
  const [showEmbedded, setShowEmbedded] = useState(false);
  const articleExcerptClosingTags = article.excerptOriginal.match(/(<\/.*?>)/g);
  const articleExcerptClosingTagIndex = article.excerptOriginal.lastIndexOf(articleExcerptClosingTags[articleExcerptClosingTags.length - 1]);
  const history = useHistory();

  const markAsReadOnWaypointEvent = useMarkAsReadWaypoint();
  const feedConfiguration = useFeedConfiguration();
  const tryGallery = useTryGallery(article, openGallery);

  let articleClass = article.isImportant ? `${APP_LANG === 'sk' ? 'mnt-Important-sk' : ''}` : '';

  useEffect(() => {
    const internalEmbedContent = feedConfiguration.showEmbed && article.embed && article.embed.filter((embed) => embed.type === 'dn_internal')?.[0];
    if (internalEmbedContent) {
      const internalEmbedBackgroundColor = isDarkMode ? internalEmbedContent?.background_color_dark : internalEmbedContent?.background_color_light;
      setInternalContentBackground(internalEmbedBackgroundColor);
    }
  }, [article.embed, isDarkMode]);

  useEffect(() => {
    if (isExpanded) {
      thisDiv.current.parentElement.style.maxHeight = '150em';
      /// set max-height to auto after 700ms(it will be offscreen and safe to finish animation)
      setTimeout(() => {
        if (thisDiv.current) {
          thisDiv.current.parentElement.style.maxHeight = null;
        }
      }, 700);
    }
  }, [isExpanded]);

  useEffect(() => {
    const allHyperlinks = thisDiv.current.querySelectorAll('a');
    allHyperlinks.forEach((hyperlink) => {
      if (hyperlink.href.includes('https://dennikn.sk/minuta/live')) {
        hyperlink.addEventListener('click', (e) => {
          e.preventDefault();
          const internalLink = hyperlink.href.replace('https://dennikn.sk/minuta', '');
          history.push(internalLink);
        });
      }
    });
  }, [thisDiv.current, isExpanded]);

  useEffect(() => {
    article.readMoreCountOfWords > 0 && setReadMoreLabel(messages.readMoreFn(article.readMoreCountOfWords));
  }, []);

  const WaypointOrEmpty = (useWaypoint, children) => {
    if (useWaypoint)
      return (
        <Waypoint scrollableAncestor={'window'} onLeave={markAsReadOnWaypointEvent(article.id)}>
          {children}
        </Waypoint>
      );
    return <>{children}</>;
  };

  return WaypointOrEmpty(
    !seen,
    <StyledArticle className={'mnt-FeedArticle js-hook-feed-article' + (seen ? '' : ' mnt-FeedArticleSeen') + (article.isImportant ? ' mnt-FeedArticleImportant' : '')} ref={thisDiv} fontSetting={dynamicFontSize}>
      <FeedArticleToolbar article={article} toggleShareMenuFn={toggleShareMenuFn} showShareMenu={showShareMenu} isImportant={!!article.isImportant} />
      {feedConfiguration.allowExpandedPosts || isExpanded ? (
        <div onClick={(event) => tryGallery(event, false)} className={`mnt-article ${articleClass} ${isLive ? 'mnt-feed-live-article' : ''}`} dangerouslySetInnerHTML={{ __html: isExpanded ? article.excerpt + article.content : article.excerpt }} />
      ) : (
        <div
          onClick={(event) => tryGallery(event, false)}
          className={`mnt-article ${articleClass}`}
          dangerouslySetInnerHTML={{
            __html: APP_LANG === 'cz' && article.hasReadMore ? `${article.excerptOriginal.slice(0, articleExcerptClosingTagIndex)} <a href=${article.url}>(...)</a>${article.excerptOriginal.slice(articleExcerptClosingTagIndex)}` : article.excerptOriginal,
          }}
        />
      )}

      {/******************** Read More   ****************************/}

      {feedConfiguration.allowExpandedPosts && /// only if extended posts are allowed
        article.hasReadMore &&
        !isExpanded && (
          <button
            className={'mnt-btn mnt-readMore'}
            onClick={() => {
              thisDiv.current.parentElement.style.maxHeight = thisDiv.current.parentElement.offsetHeight + 'px';
              setIsExpanded(true);
              trackReadMore('' + article.id);
            }}
            aria-label={messages.areaReadMore}
          >
            {readMoreLabel || messages.readMorePlaceholder}
            <SpriteMap sprite={'carret'} />
          </button>
        )}

      {/******************** Image       ****************************/}

      {article.image && !hideImage && <ArticleImage article={article} openGallery={() => tryGallery(null, true)} />}

      {/******************** embed       ****************************/}
      {feedConfiguration.showEmbed &&
        article.embed &&
        article.embed.length > 0 &&
        article.embed
          .filter((embed) => (embed.type === 'youtube') | (embed.type === 'instagram'))
          .map((embed, i) => (
            /// show on waypoint
            <Waypoint key={i} onEnter={() => setShowEmbedded(embed)} viewportBottom={300} scrollableAncestor={scrollableAncestor} />
          ))}

      {feedConfiguration.showEmbed && showEmbedded !== false && <ArticleEmbed url={showEmbedded.link} type={showEmbedded.type} isShort={showEmbedded.isShort} html={showEmbedded.html} />}

      {/******************** Tags        ****************************/}

      {(appType === 'feed' || contentType !== 'live') && feedConfiguration.showTags && article.tags && article.tags.length > 0 && ((article.hasReadMore && isExpanded) || !article.hasReadMore) ? (
        <>
          {contentType !== 'live' && (
            <div className={'mnt-tags-article-wrapper'}>
              {feedConfiguration.showEmbed && /// combination showTags === true && showEmbed === false
                  article.embed &&
                  article.embed
                      /// currently we are embedding youtube in an iframe, so there is no button for it in this list
                      .filter((embed) => embed.type !== 'youtube' && embed.type !== 'instagram')
                      .map((embed, i) => <EmbedButton key={i} label={embed.action_text} url={embed.link} useEmbeded={false} onClick={() => setShowEmbedded(embed)} backgroundColor={internalContentBackground} />)}

              <div className={'mnt-tags' + (showShareMenu ? '' : ' mnt-fade-end')} role="region" aria-label={messages.areaPopularPostsList}>
                {article.tags.map((tag) => (
                  <TagLink key={tag.id} id={tag.id} slug={tag.slug} isHighlighted={false}>
                    {tag.name}
                  </TagLink>
                ))}
              </div>
            </div>
          )}
        </>
      ) : (
        <div className={'mnt-tags-article-wrapper'}>
          {feedConfiguration.showEmbed &&
            /// in case there are no tags, but there are embedded media
            article.embed &&
            article.embed
              /// currently we are embedding youtube in an iframe, so there is no button for it in this list
              .filter((embed) => embed.type !== 'youtube' && embed.type !== 'instagram')
              .map((embed, i) => <EmbedButton key={i} label={embed.action_text} url={embed.link} useEmbeded={false} onClick={() => setShowEmbedded(embed)} backgroundColor={internalContentBackground} />)}
        </div>
      )}
    </StyledArticle>
  );
};

FeedArticle.propTypes = {
  /// Post object from /mpm/posts
  article: PropTypes.object.isRequired,
  seen: PropTypes.bool.isRequired,
};

export default withGTMMTrackerContext(withGallery(withAppContext(withMessages(withUserSettingsContext(FeedArticle)))));
