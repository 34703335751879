import { useEffect, useState } from 'react';
import { useAppContextAppType, useAppContextIsMobile, useAppContextIsTablet } from '../../ContextProviders/AppContextProvider';
import { useAutoAddNewPostsSetting } from '../../ContextProviders/UserSettingsContextProvider';

const defaultConfig = {
  autoAddNewPosts: true,
  autoAddNewLives: true,
  lazyLoad: true,
  allowExpandedPosts: true,
  showGallery: true,
  showTags: true,
  showEmbed: true,
};

function getConfig(appType, isMobile, isTablet, autoAddNewPostsCookie) {
  if (appType === 'app' || appType === 'feed') {
    return {
      ...defaultConfig,
      autoAddNewPosts: !isMobile, /// mobile is using manual type
    };
  }
  if (appType === 'widget') {
    /// Widget has configurable autoAddNewPosts feature
    if (isMobile || isTablet) {
      return {
        ...defaultConfig,
        autoAddNewPosts: autoAddNewPostsCookie,
      };
    }
    return {
      ...defaultConfig,
      lazyLoad: false,
      autoAddNewPosts: autoAddNewPostsCookie,
      allowExpandedPosts: false,
      showEmbed: true,
      showGallery: false,
      showTags: false,
    };
  }
}

export default function useFeedConfiguration() {
  const isMobile = useAppContextIsMobile();
  const isTablet = useAppContextIsTablet();
  const appType = useAppContextAppType();
  const autoAddNewPostsCookie = useAutoAddNewPostsSetting();

  const [currentConfiguration, setConfiguration] = useState(() => getConfig(appType, isMobile, isTablet));

  useEffect(() => {
    setConfiguration(getConfig(appType, isMobile, isTablet, autoAddNewPostsCookie));
  }, [isMobile, autoAddNewPostsCookie]);

  return currentConfiguration;
}
