import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, Prompt, useHistory, useLocation, useParams } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import Ticker from '../../Components/Controllers/Ticker';
import FeedArticle from '../../Components/FeedArticle';
import FeedSticky from '../../Components/FeedSticky';
import { shareMenuVisibleForArticleIdSelector, toggleShareMenuForArticle, closeShareMenu, closeMobileMenu, userIdSelector } from '../../Redux/appSlice';
import {
  /// postsSlice
  tickPosts,
  stickyContentSelector,
  hasErrorSelector,
  hasMorePostsSelector,
  countOfPendingPostsSelector,
  applyPendings,
  fetchPosts,
  onLocationChanged,
  lastTimestampSelector,
  isLoadingSelector,
  invalidateData,
  errorMessageSelector,
  setPageKey,
  hasHistorySelector,
  hasPostsSelector,
  originalPostsSelector,
  newPostsSelector,
  olderPostsSelector,
  invalidateOldAndNew,
  fetchOriginalPosts,
  isEmptySelector,
} from '../../Redux/postsSlice';
import EndOfFeed from '../../Components/EndOfFeed';

import { matchUrl } from '../Routes';
import { withScrollingHelperContext } from '../../ContextProviders/ScrollingHelperContext';
import { withToast } from '../../ContextProviders/ToastContextProvider';
import { withMessages } from '../../ContextProviders/LanguageContextProvider';
import FeedArticleSkeleton from '../../Components/Skeletons/FeedArticleSkeleton';
import { withAppContext } from '../../ContextProviders/AppContextProvider';
import MetaData from '../../Components/MetaData';
import { withUserSettingsContext } from '../../ContextProviders/UserSettingsContextProvider';
import { replaceTags } from '../../Redux/setupSlice';
import useTrackPageEvents from '../../Components/Hooks/useTrackPageEvents';
import useFeedConfiguration from '../../Components/Hooks/useFeedConfiguration';
import useHashFeedPosition from '../../Components/Hooks/useHashFeedPosition';
import useScrollableAncestor from '../../Components/Hooks/useScrollableAncestor';
import useContinueOnMnt from '../../Components/Hooks/useContinueOnMnt';
import { getCookie } from 'react-use-cookie';
import { withGTMMTrackerContext } from '../../ContextProviders/GTMTrackerContextProvider';

const Feed = ({
  // wrappers
  messages,
  scrollToTop,
  showToast,
  appType,
  totalInactiveTime,
  lastArticleSeen,
  setLastArticleSeen,
  sportEnabled,
  setDarkMode,
  trackUserLogin,
  trackPwaPromptAppeared,
  trackPwaPromptClicked,
  trackIfUsesPwa,
  trackPageLoaded,
}) => {
  const feedConfiguration = useFeedConfiguration();
  const scrollableAncestor = useScrollableAncestor(appType);
  let location = useLocation();
  const dispatch = useDispatch();
  const routeParams = useParams();
  const history = useHistory();
  const [sportStatus, setSportStatus] = useState(sportEnabled);
  const [dynamicFontSize, setDynamicFontSize] = useState(undefined);

  const countOfPendingPosts = useSelector(countOfPendingPostsSelector);
  const hasMorePosts = useSelector(hasMorePostsSelector);
  const hasError = useSelector(hasErrorSelector);
  const isEmpty = useSelector(isEmptySelector);
  const errorMessage = useSelector(errorMessageSelector);
  const stickyContent = useSelector(stickyContentSelector);
  const originalPosts = useSelector(originalPostsSelector);
  const newPosts = useSelector(newPostsSelector);
  const olderPosts = useSelector(olderPostsSelector);
  const isLoading = useSelector(isLoadingSelector);
  const hasHistory = useSelector(hasHistorySelector);
  const hasPosts = useSelector(hasPostsSelector);

  const showShareMenuForArticleId = useSelector(shareMenuVisibleForArticleIdSelector);
  const lastTimestamp = useSelector(lastTimestampSelector);

  const [lastArticleSeenLocalCopy, setLastArticleSeenLocalCopy] = useState(lastArticleSeen);
  const userIdSelected = useSelector(userIdSelector);
  const nToken = getCookie(ENV_COOKIE_N_TOKEN);

  const [pageLoaded, setPageLoaded] = useState(false);
  useEffect(() => {
    pageLoaded && trackPageLoaded();

    // check if in installed pwa app
    pageLoaded && appType === 'app' && trackIfUsesPwa(window.matchMedia('(display-mode: standalone)').matches);
  }, [pageLoaded]);

  useEffect(() => {
    if (userIdSelected === 0 && nToken !== '') {
      return;
    }
    if (userIdSelected === 0) {
      return;
    }
    pageLoaded && trackUserLogin(userIdSelected, true);
  }, [userIdSelected, pageLoaded]);

  const [newlyRenderedComponent, setNewlyRenderedComponent] = useState(true);
  useEffect(() => {
    /// double tick fix.
    /// component <Feed is reused on route change.
    /// this flag can be used to determine if component is new or it is being reused
    setNewlyRenderedComponent(false);
  }, []);

  appType === 'widget' && useContinueOnMnt();

  const handlePwaInstall = async (e) => {
    // send analytics event that PWA install promo was shown.
    trackPwaPromptAppeared();
    // check outcome
    const { outcome } = await e.userChoice;
    trackPwaPromptClicked(outcome);
  };

  const [allPosts, setAllPosts] = useState(originalPosts);

  useEffect(() => {
    setAllPosts([...newPosts, ...originalPosts, ...olderPosts]);
  }, [newPosts, originalPosts, olderPosts]);

  useHashFeedPosition(allPosts);
  /// GTM and REMP tracking ----------------------------------------------------
  useTrackPageEvents(stickyContent);
  /// --------------------------------------------------------------------------

  const tryHistory = () => {
    if (history.location.key) {
      dispatch(setPageKey(history.location.key));
      /// this will be run ONLY on a first page
      (hasHistory || !hasPosts) && onTick();
    } else {
      history.replace(location.pathname);
      setTimeout(tryHistory, 0);
    }
  };

  /**
   * decide what to do with pending posts
   * decision is made with help of the flag: autoAddNewPosts
   */
  useEffect(() => {
    if ((feedConfiguration.autoAddNewPosts && countOfPendingPosts > 0) || (!feedConfiguration.autoAddNewPosts && countOfPendingPosts > 30)) {
      setLastArticleSeen(Date.now(), { SameSite: 'Lax', Secure: true });
      dispatch(applyPendings());
    }
  }, [countOfPendingPosts, feedConfiguration]);

  /**
   * method to be used by <Ticker component
   */
  const onTick = useCallback(() => {
    if (location.key === undefined) {
      return;
    }
    const routeMatch = matchUrl(location.pathname, appType);
    setSportStatus(sportEnabled);

    if (!sportEnabled && routeMatch.routeName === 'HOMEPAGE') {
      APP_LANG === 'sk' && dispatch(tickPosts({ routeMatch, routeParams, exclude_category: 545 }));
      APP_LANG === 'cz' && dispatch(tickPosts({ routeMatch, routeParams, exclude_category: 13 }));
    } else {
      dispatch(tickPosts({ routeMatch, routeParams }));
      if (window && window.innerWidth >= 768) {
        setLastArticleSeen(Date.now(), { SameSite: 'Lax', Secure: true });
      }
    }
  }, [location, sportEnabled, routeParams]);

  const applyPendingsCallback = () => {
    if (appType === 'app' || appType === 'feed') {
      const routeMatch = matchUrl(location.pathname, appType);
      if (routeMatch.routeName === 'HOMEPAGE') {
        setLastArticleSeen(Date.now(), { SameSite: 'Lax', Secure: true });
      }
    }
    dispatch(applyPendings());
    scrollToTop();
  };

  const waypointOnEnter = () => {
    if (isLoading || (hasError && Date.now() / 1000 - lastTimestamp < 10)) return;
    if (allPosts.length === 0 || isEmpty) return;
    const routeMatch = matchUrl(location.pathname, appType);
    let excludeCategory = null;
    if (!sportEnabled) {
      excludeCategory = APP_LANG === 'sk' ? 545 : excludeCategory;
      excludeCategory = APP_LANG === 'cz' ? 13 : excludeCategory;
    }

    dispatch(
      fetchPosts({
        routeMatch,
        routeParams,
        beforeId: olderPosts.length > 0 ? olderPosts[olderPosts.length - 1].id : originalPosts.length > 0 ? originalPosts[originalPosts.length - 1]?.id : 0,
        exclude_category: excludeCategory,
      })
    );
  };

  useEffect(() => {
    if (sportStatus !== sportEnabled) {
      const routeMatch = matchUrl(location.pathname, appType);
      setSportStatus(sportEnabled);
      if (routeMatch.routeName === 'HOMEPAGE') {
        dispatch(invalidateData());
        scrollToTop();
        onTick();
      }
    }
  }, [sportEnabled, onTick]);

  const [wasOnceFetched, setWasOnceFetched] = useState(false);

  const fetchNonSportPosts = (routeMatch) => {
    APP_LANG === 'sk' && dispatch(fetchOriginalPosts({ routeMatch, routeParams, exclude_category: 545 }));
    APP_LANG === 'cz' && dispatch(fetchOriginalPosts({ routeMatch, routeParams, exclude_category: 13 }));
  };

  useEffect(() => {
    if (!sportEnabled && !wasOnceFetched) {
      const routeMatch = matchUrl(location.pathname, appType);
      fetchNonSportPosts(routeMatch);
    }
    setWasOnceFetched(true);
  }, [sportEnabled]);

  useEffect(() => {
    dispatch(closeShareMenu());
    dispatch(closeMobileMenu());
    dispatch(invalidateOldAndNew());
    scrollToTop();
  }, [location]);

  useEffect(() => {
    if (appType !== 'app') {
      return;
    }
    const routeMatch = matchUrl(location.pathname, appType);
    try {
      if (routeMatch.routeName === 'HOMEPAGE') {
        let currentTags;
        currentTags = [
          ...stickyContent.tags.map((it) => ({
            ...it,
            settings: stickyContent.trending_tags ? ['highlight'] : [],
          })),
        ];
        currentTags = [...currentTags, ...stickyContent.trending_tags];
        dispatch(replaceTags({ tags: currentTags }));
      }
    } catch (ex) {}
  }, [location, stickyContent]);

  const setFontSize = (normalSizes, importantSizes) => {
    setDynamicFontSize([normalSizes, importantSizes]);
  };

  const sendPostMessage = (object) => {
    !!webkit ? webkit.messageHandlers.app.postMessage(object) : window.app.postMessage(object);
  };

  useEffect(() => {
    if (appType === 'feed') {
      const setContainerWidth = (value) => {
        const wrapper = document.querySelector('.mnt-layout__content-feed');
        wrapper.style.maxWidth = `${value}px`;
      };
      window.setDarkMode = setDarkMode;
      window.setContainerWidth = setContainerWidth;
      window.setFontSize = setFontSize;
      window.sendPostMessage = sendPostMessage;
    }
    setPageLoaded(true);
    appType === 'app' && window.addEventListener('beforeinstallprompt', handlePwaInstall);
    if (location.state && 'fromNonExistingPage' in location.state && location.state.fromNonExistingPage === true) {
      if (!(appType === 'feed' && window.hasOwnProperty('__INITIAL_URL__') && !!window.__INITIAL_URL__)) {
        window.history.replaceState({}, document.title);
        showToast(messages.urlNotFound);
      }
    }
    /// on Application start, set lastArticleSeenLocalCopy
    setLastArticleSeenLocalCopy(lastArticleSeen > 0 ? lastArticleSeen : Date.now());
    setLastArticleSeen(Date.now(), { SameSite: 'Lax', Secure: true });
    tryHistory();
    return () => {
      appType === 'app' && window.removeEventListener('beforeinstallprompt', handlePwaInstall);
    };
  }, []);

  useEffect(() => {
    const tagUrl =
      stickyContent &&
      stickyContent.type === 'tag' &&
      generatePath(appType !== 'feed' ? ENV_APP_TAG_ROUTE : ENV_APP_TAG_ROUTE.replace(WEBAPP_ROOT_PATH, FEED_ROOT_PATH), {
        id: stickyContent.id,
        tag: stickyContent.slug,
      });
    const categoryUrl =
      stickyContent &&
      stickyContent.type === 'category' &&
      generatePath(appType !== 'feed' ? ENV_APP_CATEGORY_ROUTE : ENV_APP_CATEGORY_ROUTE.replace(WEBAPP_ROOT_PATH, FEED_ROOT_PATH), {
        id: stickyContent.id,
        category: stickyContent.slug,
      });
    const liveUrl =
      stickyContent &&
      stickyContent.type === 'live' &&
      generatePath(appType !== 'feed' ? ENV_APP_LIVE_ROUTE : ENV_APP_LIVE_ROUTE.replace(WEBAPP_ROOT_PATH, FEED_ROOT_PATH), {
        id: stickyContent.id,
        live: stickyContent.slug,
      });
    if (tagUrl && location.pathname !== tagUrl) {
      history.push(tagUrl);
    }
    if (categoryUrl && location.pathname !== categoryUrl) {
      history.push(categoryUrl);
    }
    if (liveUrl && location.pathname !== liveUrl) {
      history.push(liveUrl);
    }
  }, [stickyContent]);

  useEffect(() => {
    if (hasError) {
      if (errorMessage === 'no_taxonomy') {
        history.replace(ENV_APP_HOMEPAGE_ROUTE);
      } else {
        showToast(messages.networkError);
      }
    }
  }, [hasError, errorMessage]);

  useEffect(() => {
    if (totalInactiveTime > ENV_RELOAD_AFTER_IDLE_TIME) {
      if (appType === 'app' && window && window.location) {
        /// in case of app, refresh page
        window.location.reload();
        document.scrollToTop();
      } else {
        /// in case of widget, just appendPendings
        countOfPendingPosts > 0 && applyPendingsCallback();
      }
    }
  }, [totalInactiveTime]);

  const saveScrollPosition = (location) => {
    dispatch(
      onLocationChanged({
        pageKey: location.key === undefined ? 'firstpage' : location.key,
        scrollPosition: 0,
      })
    );
    return true;
  };

  return (
    <div className={'mnt-Feed'}>
      <Prompt message={saveScrollPosition} />

      {appType === 'app' && <MetaData metaData={['live', 'category', 'tag'].includes(stickyContent.type) ? stickyContent : MetaData} />}

      <Ticker
        //
        lastTimestamp={lastTimestamp}
        timeoutAfterSeconds={ENV_POSTS_TICKER_SPEED}
        onTick={onTick}
        skipFirst={newlyRenderedComponent}
        tickerSpeedSeconds={ENV_POSTS_TICKER_SPEED}
        fireOnChange={[location]}
      />

      {isLoading && originalPosts.length === 0 && <FeedArticleSkeleton repeat={5} />}

      {isLoading && originalPosts.length === 0 && <FeedArticleSkeleton repeat={5} />}

      {Object.keys(stickyContent).length > 0 && <FeedSticky stickyContent={stickyContent} dynamicFontSize={dynamicFontSize} />}

      <div className={'mnt-Posts'}>
        {stickyContent.type === 'live' && appType === 'feed' && <hr className={'mnt-feed-hr'} />}
        {newPosts.length > 0 &&
          newPosts.map((article) => (
            <div key={article.id} id={article.id} style={{ transition: 'max-height .7s linear' }} className={'mnt-Post-hash'}>
              <FeedArticle
                //
                article={article}
                showShareMenu={showShareMenuForArticleId === article.id}
                toggleShareMenuFn={() => dispatch(toggleShareMenuForArticle({ articleId: article.id }))}
                seen={article.forceNotSeen ? false : article.published_at_date < lastArticleSeenLocalCopy || article.seen}
                expanded={stickyContent.type === 'live' && !article.isCollapsed}
                hideImage={!!(article.shouldHideImageOnHome && location.pathname === '/minuta/')}
                dynamicFontSize={dynamicFontSize}
                contentType={stickyContent.type}
              />
              {stickyContent.type === 'live' && appType === 'feed' && <hr className={'mnt-feed-hr'} />}
            </div>
          ))}
        {originalPosts.map((article, i) => (
          <Fragment key={article.id}>
            <div id={article.id} style={{ transition: 'max-height .7s linear' }} className={'mnt-Post-hash'}>
              {olderPosts.length === 0 && feedConfiguration.lazyLoad && hasMorePosts && originalPosts.length - 15 === i && <Waypoint onEnter={waypointOnEnter} viewportBottom={300} scrollableAncestor={scrollableAncestor} />}
              <FeedArticle
                article={article}
                showShareMenu={showShareMenuForArticleId === article.id}
                toggleShareMenuFn={() => dispatch(toggleShareMenuForArticle({ articleId: article.id }))}
                seen={article.forceNotSeen ? false : article.published_at_date < lastArticleSeenLocalCopy || article.seen}
                expanded={stickyContent.type === 'live' && !article.isCollapsed}
                hideImage={!!(article.shouldHideImageOnHome && location.pathname === '/minuta/')}
                dynamicFontSize={dynamicFontSize}
                contentType={stickyContent.type}
              />
              {stickyContent.type === 'live' && appType === 'feed' && <hr className={'mnt-feed-hr'} />}
            </div>
            {i === 4 && appType === 'widget' && <div id={'mpm_top'}></div>}
            {i === 15 && appType === 'widget' && <div id={'mpm_middle'}></div>}
          </Fragment>
        ))}
        {olderPosts.length > 0 &&
          olderPosts.map((article, i) => (
            <div key={article.id} id={article.id} style={{ transition: 'max-height .7s linear' }} className={'mnt-Post-hash'}>
              {feedConfiguration.lazyLoad && hasMorePosts && olderPosts.length - 15 === i && <Waypoint onEnter={waypointOnEnter} viewportBottom={300} scrollableAncestor={scrollableAncestor} />}
              <FeedArticle
                //
                article={article}
                showShareMenu={showShareMenuForArticleId === article.id}
                toggleShareMenuFn={() => dispatch(toggleShareMenuForArticle({ articleId: article.id }))}
                seen={article.forceNotSeen ? false : article.published_at_date < lastArticleSeenLocalCopy || article.seen}
                expanded={stickyContent.type === 'live' && !article.isCollapsed}
                hideImage={!!(article.shouldHideImageOnHome && location.pathname === '/minuta/')}
                dynamicFontSize={dynamicFontSize}
                contentType={stickyContent.type}
              />
              {stickyContent.type === 'live' && appType === 'feed' && <hr className={'mnt-feed-hr'} />}
            </div>
          ))}

        {/** if page is not loading, user is on the bottom of the screen and there are new posts, we will run loader */}
        {feedConfiguration.lazyLoad && !isLoading && hasMorePosts && <Waypoint onEnter={waypointOnEnter} viewportBottom={300} scrollableAncestor={scrollableAncestor} />}
        {/** one more skeleton at the enf of the page */}
        {feedConfiguration.lazyLoad && isLoading && hasMorePosts && <FeedArticleSkeleton />}
        {/** end of the feed... */}
        {!hasMorePosts && <EndOfFeed />}
      </div>
    </div>
  );
};

export default withGTMMTrackerContext(withScrollingHelperContext(withAppContext(withUserSettingsContext(withToast(withMessages(Feed))))));
