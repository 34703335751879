import React from 'react';

const SpriteMap = ({ sprite, classname }) => {
  return (
    <svg className={classname}>
      <use href={'#sprite-' + sprite} />
    </svg>
  );
};

export default SpriteMap;
