import React, { Fragment, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import IconLink from '../../Components/IconLink';
import LiveLink from '../../Components/LiveLink';
import MenuSearchBox from '../../Components/MenuSearchBox';
import MenuSectionTitle from '../../Components/MenuSectionTitle';
import TagLink from '../../Components/TagLink';
import CategoryLink from '../../Components/CategoryLink';
import Switcher from '../../Components/Switcher';

import {
  // Setup Slice
  isLoadingSelector,
  getLatestMenuItem,
  getImpotantMenuItem,
  getLiveMenuItem,
  getCategoriesMenuItems,
  getTagsMenuItems,
  getLiveArticlesSelector,
  featureSelector,
} from '../../Redux/setupSlice';
import { withMessages } from '../../ContextProviders/LanguageContextProvider';
import MenuSkeleton from '../../Components/Skeletons/MenuSkeleton';
import { withUserSettingsContext } from '../../ContextProviders/UserSettingsContextProvider';
import { useState } from 'react';
import { matchUrl } from '../Routes';
import { useLocation } from 'react-router-dom';
import { withGTMMTrackerContext } from '../../ContextProviders/GTMTrackerContextProvider';
import { withAppContext } from '../../ContextProviders/AppContextProvider';

const Menu = ({ messages, closeMobileMenu, isMobileMenuOpened, isDarkMode, setDarkMode, sportEnabled, setSportEnabled, trackSportToggle, trackThemeToggle, appType }) => {
  const menuRef = useRef();
  const history = useHistory();
  const location = useLocation();

  const [routeDetails, setRouteDetails] = useState('');

  const isLoading = useSelector(isLoadingSelector);
  const latestMenuItem = useSelector(getLatestMenuItem);
  const livesMenuItem = useSelector(getLiveMenuItem);
  const liveArticles = useSelector(getLiveArticlesSelector);
  const importantMenuItem = useSelector(getImpotantMenuItem);
  const categoriesMenuItems = useSelector(getCategoriesMenuItems);
  const tagsMenuItems = useSelector(getTagsMenuItems);
  const feature = useSelector(featureSelector);
  const hasFeature = feature && feature.url && feature.height > 0;

  /// issue with blinking menu..
  const [firstTimeLoad, setFirstTimeLoad] = useState(true);
  useEffect(() => !isLoading && setFirstTimeLoad(false), [isLoading]);

  const searchForQuery = (searchQuery) => {
    closeMobileMenu();
    const url = generatePath(ENV_APP_SEARCH_ROUTE, {
      query: searchQuery,
    });
    history.push(url);
  };

  const localSetSportEnabled = (newState) => {
    setSportEnabled(newState);
    trackSportToggle(newState);
  };

  const localSetDarkMode = (newState) => {
    setDarkMode(newState);
    trackThemeToggle(newState);
  };

  useEffect(() => {
    function onWindowClickEventListener(event) {
      /// everything outside of this menu (menuRef) will trigger closeMobileMenu
      if (isMobileMenuOpened === true && menuRef.current && !menuRef.current.contains(event.target)) {
        closeMobileMenu();
      }
    }

    /// we will create an event handler for "window" clicks
    /// it will be used to close open menues
    window.addEventListener('click', onWindowClickEventListener);

    /// unregister event listener
    return () => {
      window.removeEventListener('click', onWindowClickEventListener);
    };
  }, [menuRef, isMobileMenuOpened]);

  useEffect(() => {
    const routeMatch = matchUrl(location.pathname, appType);
    setRouteDetails(routeMatch);
  }, [location]);

  return (
    <div className={'mnt-Menu'} ref={menuRef}>
      <MenuSearchBox onSearchConfirm={searchForQuery} />
      <div className={'mnt-NavOverlay'} style={hasFeature && { maxHeight: `calc(100vh - 170px - ${feature.height}px)` }}>
        {isLoading && firstTimeLoad && <MenuSkeleton />}

        {!(isLoading && firstTimeLoad) && (
          <Fragment>
            <nav className={`mnt-sideMenu top ${APP_LANG === 'sk' ? 'mnt-bordered_bottom' : ''}`}>
              {latestMenuItem && (
                <IconLink icon={'home_vs-' + APP_LANG} to={ENV_APP_HOMEPAGE_ROUTE} isActive={routeDetails.routeName === 'HOMEPAGE'}>
                  {latestMenuItem.name}
                </IconLink>
              )}

              {importantMenuItem && (
                <IconLink icon={'notifications-' + APP_LANG} to={ENV_APP_IMPORTANT_ROUTE} isActive={routeDetails.routeName === 'IMPORTANT'}>
                  {importantMenuItem.name}
                </IconLink>
              )}

              {ENV_SHOW_LIVES && livesMenuItem && (
                <IconLink extraClass={`${APP_LANG === 'cz' ? 'mnt-bordered' : ''}`} icon={'online-' + APP_LANG} to={ENV_APP_LIVE_ARCHIVE_ROUTE} isActive={routeDetails.routeName === 'LIVE_ARCHIVE'}>
                  {livesMenuItem.name}
                </IconLink>
              )}
            </nav>

            {ENV_SHOW_LIVES && liveArticles.length > 0 && (
              <div className={'mnt-LiveLinks'}>
                <MenuSectionTitle>{messages.activeOnlineLiveNews}</MenuSectionTitle>
                {liveArticles.map(
                  (live) =>
                    live.isShown && (
                      <Fragment key={live.id}>
                        {live.isNotSport && (
                          <LiveLink id={live.id} slug={live.slug}>
                            {live.name}
                          </LiveLink>
                        )}
                        {!live.isNotSport && sportEnabled && (
                          <LiveLink id={live.id} slug={live.slug}>
                            {live.name}
                          </LiveLink>
                        )}
                      </Fragment>
                    )
                )}
              </div>
            )}

            {/******************** Tags          ****************************/}

            {tagsMenuItems && (
              <div className={'mnt-sideNav'}>
                <MenuSectionTitle>{messages.currentTags}</MenuSectionTitle>
                <div className={'mnt-tags'} role="region" aria-label={messages.areaPopularPostsList}>
                  {tagsMenuItems.map((tag) => (
                    <TagLink key={tag.id} id={tag.id} slug={tag.slug} settings={tag.settings}>
                      {tag.name}
                    </TagLink>
                  ))}
                </div>
              </div>
            )}

            {/******************** Categories      ****************************/}

            {categoriesMenuItems && (
              <nav className={'mnt-sideNav'}>
                <MenuSectionTitle>{messages.categories}</MenuSectionTitle>
                <nav className={'mnt-sideMenu'}>
                  {categoriesMenuItems.map((it) => (
                    <CategoryLink
                      id={it.id}
                      slug={it.slug}
                      key={it.id}
                      isActive={routeDetails.routeName === 'CATEGORY' && routeDetails.params.id == it.id}
                      additionalProps={{
                        className: 'mnt-NavLink',
                      }}
                    >
                      {it.name}
                    </CategoryLink>
                  ))}
                </nav>
              </nav>
            )}

            {/******************** Settings       ****************************/}
            {APP_LANG === 'sk' && (
              <nav className={'mnt-sideNav'}>
                <MenuSectionTitle>{messages.settings}</MenuSectionTitle>
                <nav className={'mnt-sideMenu mnt-settings'}>
                  <div className={'mnt-btnGroup'}>
                    <Switcher name={messages.showSport} value={sportEnabled} onChange={localSetSportEnabled} ariaLabel={messages.showSportLong} />
                    <Switcher name={messages.darkMode} value={isDarkMode} onChange={localSetDarkMode} ariaLabel={messages.darkMode} />
                  </div>
                </nav>
              </nav>
            )}
            {APP_LANG === 'cz' && (
              <nav className={'mnt-settings'}>
                <div className={'mnt-btnGroup'}>
                  <Switcher name={messages.showSport} value={sportEnabled} onChange={localSetSportEnabled} ariaLabel={messages.showSportLong} />
                  <Switcher name={messages.darkMode} value={isDarkMode} onChange={localSetDarkMode} ariaLabel={messages.darkMode} />
                </div>
              </nav>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default withAppContext(withGTMMTrackerContext(withUserSettingsContext(withMessages(Menu))));
