import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SWLink from './SWLink';
import { withAppContext } from '../ContextProviders/AppContextProvider';
import { useLocation } from 'react-router-dom';
import { lastTimestampSelector } from '../Redux/postsSlice';
import { useSelector } from 'react-redux';
import { withMessages } from '../ContextProviders/LanguageContextProvider';

const ToolbarLogo = ({ animateLogo, appType, messages }) => {
  const location = useLocation();
  const lastTimestamp = useSelector(lastTimestampSelector);
  const [shouldAnimate, setShouldAnimate] = useState(animateLogo);

  useEffect(() => {
    setShouldAnimate(true);

    const timer = setTimeout(() => {
      setShouldAnimate(false);
    }, 4300);

    return () => {
      clearTimeout(timer);
    };
  }, [lastTimestamp]);

  const handleAnimateLogo = () => {
    setShouldAnimate(true);
    setTimeout(() => {
      setShouldAnimate(false);
    }, 4300);
  };

  return (
    <SWLink to={ENV_APP_HOMEPAGE_ROUTE} className={`mnt-logoMnt ${APP_LANG === 'cz' ? 'mnt-p-0' : ''}`}>
      <div className={shouldAnimate ? `mnt-animate mnt-loader-${APP_LANG}` : `mnt-loader-${APP_LANG}`} onClick={handleAnimateLogo} />
      <span className={'mnt-logoTitle'} onClick={handleAnimateLogo}>
        {messages.mntSK}
      </span>
      {appType === 'widget' && (location.pathname === '/minuta/' || location.pathname === '/') && (
        <span className={'mnt-logoTitle-sans'} onClick={handleAnimateLogo}>
          {messages.appName}
        </span>
      )}
    </SWLink>
  );
};

ToolbarLogo.propTypes = {
  animateLogo: PropTypes.bool.isRequired,
};

export default withMessages(withAppContext(ToolbarLogo));
