import React from 'react';
import { InstagramEmbed } from 'react-social-media-embed';
import { YouTubeEmbed } from 'react-social-media-embed';
import parse from 'html-react-parser';

const ArticleEmbed = ({ url, type, isShort, html }) => {
  if (html) {
    return parse(html);
  }
  if (type === 'youtube') {
    return <YouTubeEmbed url={url} className={`video-responsive ${type} ${isShort ? 'is-short' : ''}`} />;
  } else if (type === 'instagram') {
    return <InstagramEmbed url={url} className={`video-responsive ${type}`} />;
  }
  // else if (hostName === 'twitter') {
  //   return <TwitterEmbed id={id} align={'center'} darkTheme={isDarkMode} parentClass={'mnt-twitter-post'} />;
  // } else if (hostName === 'facebook-video') {
  //   <FacebookEmbed width="100%" postUrl={url} parentClass={'mnt-fb-post'} />;
  // } else if (hostName === 'facebook') {
  //   return <FacebookEmbed width="500" postUrl={url} parentClass={'mnt-fb-post'} />;
  // }
  return <></>;
};

export default ArticleEmbed;
